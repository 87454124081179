import React from 'react';

import { cn } from '~/utils/cn';
import useRootData from '~/utils/root';

export const GoogleButton: React.FC = () => {
  const { locale } = useRootData();

  return (
    <a
      href={`${PLAY_STORE_URL}&hl=${locale}`}
      target="_blank"
      className={cn(
        'flex flex-row items-center justify-center',
        'cursor-pointer h-[32px] rounded-[8px] bg-lightGray2 hover:bg-opacity-80',
        'pt-2 pb-2 pl-3 pr-3 gap-2 shadow-button'
      )}
      rel="noreferrer"
    >
      <img
        src="/images/play-logo.svg"
        alt="Play logo"
        className="h-[14px] w-[13px]"
      />
      <p className="font-sFProDisplay font-[600] text-black text-sm leading-5">
        Google Play
      </p>
    </a>
  );
};

/* Link */
const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=co.dygest';
