import React from 'react';

import useRootData from '~/utils/root';

import { AppleButton, GoogleButton } from './components';

export const AppStoreButton: React.FC = () => {
  const { isIOS, isAndroidOS, isMobile, isTablet } = useRootData();

  return (
    <div className="flex flex-row items-center justify-center gap-2">
      {(isMobile || isTablet) && isIOS && <AppleButton />}
      {(isMobile || isTablet) && isAndroidOS && <GoogleButton />}
      {!isIOS && !isAndroidOS && (
        <>
          <GoogleButton />
          <AppleButton />
        </>
      )}
    </div>
  );
};
